<!--订单回访-->
<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-ai-c" style="height:50px">
            <el-page-header @back="goBack" content="投诉订单详情"></el-page-header>
        </div>

        <!--回访-->
        <div class="visitBox">
            <!--投诉订单信息-->
            <div class="handleVisitBox" style="padding-top:0">
                <el-table :data="[orderDetail]" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="210px">
                    <el-table-column prop="name" label="订单信息" min-width="250" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="flex flex-ai-c" v-for="(item,index) in scope.row.orderDetails" :key="index">
                                <el-image fit="cover" :src="domain + item.product_pic" class="shopImg" :preview-src-list="[domain + item.product_pic]"></el-image>
                                <div style="width:calc(100% - 70px);margin-left:8px">
                                    <p>{{item.product_name}}</p>
                                    <p>规格：{{item.attr_name}}</p>
                                    <p>单价：{{item.price}}</p>
                                    <p>数量：{{item.number}}</p>
                                    <p>小计：{{item.price * item.number}}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="postage" width="100" label="配送费" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="discount_amount" width="100" label="优惠券抵扣" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="integral_amount" width="100" label="积分抵扣" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="balance_amount" width="100" label="余额抵扣" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="recharge_amount" width="100" label="会员卡抵扣" show-overflow-tooltip></el-table-column>
                    <el-table-column label="商品类型" width="80" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{scope.row.type==1?'秒杀':scope.row.type==2?'拼团':'普通商品'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount_payable" label="应付" width="80" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="amount" label="实付" width="80" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="status_name" label="订单状态" width="100" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="is_invoice_name" label="是否带发票" width="100" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="mobile" label="收货信息" width="228" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <p>会员卡号：{{scope.row.user ? scope.row.user.card : '暂无'}}</p>
                            <p>联系人：{{scope.row.name ? scope.row.name : '暂无'}}</p>
                            <p>电话：{{scope.row.mobile ?scope.row.mobile : '暂无'}}</p>
                            <p>地址：{{scope.row.address ? scope.row.address : '暂无'}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="配送人员信息" show-overflow-tooltip width="140">
                        <template slot-scope="scope">
                            <p>姓名：{{scope.row.delivery_name ? scope.row.delivery_name : '暂无'}}</p>
                            <p>电话：{{scope.row.delivery_mobile ? scope.row.delivery_mobile : '暂无'}}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!--投诉内容-->
            <div class="table" style="height:50px;overflow:auto">
                <el-form ref="formAuth" inline size="small" class="alertForm">
                    <el-form-item label="投诉内容：">
                        <el-input v-if="orderDetail.complains" type="textarea" class="visitArea" readonly :rows="1" resize="none" v-model="orderDetail.complains.content"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <!--添加回访记录-->
            <div class="table" style="height:calc( 100% - 390px );overflow:auto">
                <el-row class="title">回访记录</el-row>
                <el-form ref="formAuth" label-position="top" inline size="small" class="alertForm">
                    <el-form-item class="uploadForm">
                        <el-input type="textarea" class="visitArea" :rows="2" resize="none" v-model="formData.content"></el-input>
                        <el-row class="show_fj" v-if="image.length!=0">
                            <div>上传图片：</div>
                            <div class="flex flex-ai-c flex-wrap">
                                <vue-hover-mask v-for="(item,index) in image" :key="index" style="margin-right:8px">
                                    <!-- 默认插槽 -->
                                    <el-image :src="domain + item" class="visitImg" fit="cover"></el-image>
                                    <!-- action插槽 -->
                                    <template v-slot:action>
                                        <i class="el-icon-zoom-in" style="margin-right:10px" @click="handleFilePreview(index)"></i>
                                        <i class="el-icon-delete-solid" @click="clearFile(index)"></i>
                                    </template>
                                </vue-hover-mask>
                            </div>
                        </el-row>
                        <div class="flex flex-jc-sb fjSubmit">
                            <label>
                                <i class="el-icon-paperclip"></i>图片
                                <input type="file" accept="image/*" class="uploadBannerList" multiple :value="filevalue" style="display:none" @change="upload('uploadBannerList')" />
                            </label>
                            <el-button type="primary" size="mini" @click="submit()">提交</el-button>
                        </div>
                    </el-form-item>
                </el-form>

                <!--回访列表-->
                <el-form ref="formAuth" label-position="top" inline size="small" class="alertForm" style="height:calc(100% - 135px);">
                    <el-form-item class="visit_list">
                        <el-row v-for="(item,index) in list" :key="index" class="visit_showlist">
                            <div class="flex flex-ai-c" style="line-height:1.8">
                                <el-image :src="require('../../assets/avatars.png')" class="avatars" fit="cover"></el-image>
                                <div style="margin-left:10px">
                                    <p>{{item.operator}}</p>
                                    <p style="color:#777">{{item.create_time}}</p>
                                </div>
                            </div>
                            <el-row style="padding-left:50px">
                                <p>{{item.content}}</p>
                                <div class="flex flex-ai-c flex-wrap" style="padding:10px;box-sizing:border-box;">
                                    <vue-hover-mask v-for="(itemImg,idx) in item.image" :key="idx" style="margin-right:8px">
                                        <el-image :src="domain + itemImg" class="visitImg" fit="cover"></el-image>
                                        <template v-slot:action>
                                            <i class="el-icon-zoom-in" style="margin-right:10px" @click="handleFilePreview(idx,item.image)"></i>
                                        </template>
                                    </vue-hover-mask>
                                </div>
                            </el-row>
                        </el-row>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!--图片预览-->
        <el-image-viewer v-if="dialogImageVisible" :on-close="closeViewer" :url-list="viewerUrlList" />
    </div>
</template>
<script>
import axios from 'axios';
import VueHoverMask from 'vue-hover-mask';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { complaintOrderReadApi, returnVisitListApi, returnVisitSavetApi } from '@/api/order.js';
export default {
    data() {
        return {
            filevalue: '',
            list: [],
            orderDetail: {},
            formData: {},
            image: [],

            dialogImageVisible: false,
            viewerUrlList: [] //图片预览
        }
    },
    components: {
        VueHoverMask,
        ElImageViewer
    },
    mounted() {
        this.getRead();
        this.getVisitList();
    },
    methods: {
        //投诉订单详情
        getRead: function () {
            complaintOrderReadApi({
                id: this.$route.query.id
            }).then(response => {
                this.orderDetail = response;
            })
        },
        //回访记录列表
        getVisitList: function () {
            returnVisitListApi({
                order_id: this.$route.query.id
            }).then(response => {
                this.list = response.list;
            })
        },
        // 预览图片
        handleFilePreview(index, image) {
            this.viewerUrlList = [];
            //判断从上传图片点击预览or回访列表预览
            let arrImage = image ? image : this.image;
            //默认显示当前点击的图片
            let viewerUrlList = arrImage.slice(index).concat(arrImage.slice(0, index));
            //拼接图片域名
            for (let item of viewerUrlList) {
                this.viewerUrlList.push(this.domain + item);
            }
            //显示图片预览查看器
            this.dialogImageVisible = true;
        },

        // 关闭图片预览查看器
        closeViewer() {
            this.dialogImageVisible = false
        },
        //上传附件
        upload: function (uploadelement) {
            var loads = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
            let that = this
            let files = document.querySelector('.' + uploadelement).files;
            for (var i = 0; i < files.length; i++) {
                var formData = new FormData();
                formData.append('file', files[i]);
                axios.post(this.domain + '/upload/upFile', formData).then(re => {
                    loads.close();
                    if (re.data.code == 200) {
                        that.filevalue = "";
                        that.image.push(re.data.data.path);
                    } else {
                        this.$message.error(re.data.msg);
                    }
                }).catch(function (error) { // 请求失败处理
                    console.log(error);
                });
            }
        },

        //提交回访
        submit: function () {
            returnVisitSavetApi({
                order_id: this.$route.query.id,
                content: this.formData.content,
                image: this.image
            }).then(() => {
                this.formData.content = '';
                this.image = [];
                this.getVisitList();
                this.$message({
                    message: '回访提交成功！',
                    type: 'success'
                })
            })
        },
        //返回上一页
        goBack: function () {
            this.$router.go(-1)
        },
        //删除图片
        clearFile: function (index) {
            this.image.splice(index, 1)
        }
    },
}
</script>